import React from 'react'
import Layout from "../../components/layout";
import { useForm } from 'react-hook-form'
import SEO from "../../components/seo";

const ContactPage = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);
    console.log(errors);

    return (
        <div>
            <Layout>
                <SEO title="Contact" />
                <div>
                    <div className='p-12 text-center'>
                        <h1 className='text-4xl'>Questions?</h1>
                        <h3 className='text-2xl mt-4'>Please fill out this form and we'll be in touch.</h3>
                    </div>
                    <div className='flex justify-center'>
                        <form
                            className='mt-8 w-1/2'
                            onSubmit={handleSubmit(onSubmit)}
                            name='contact'
                            method="POST"
                            data-netlify='true'
                            data-netlify-honeypot="bot-field"
                        >
                            <input
                                type="hidden"
                                name="form-name"
                                value="contact"
                            />
                            <label htmlFor='first_name'>First Name</label>
                            <input
                                type="text"
                                className="form-input mt-1 block w-full border"
                                placeholder="First name"
                                name="firstName"
                                id='first_name'
                                aria-invalid={errors.first_name ? "true" : "false"}
                                {...register('first_name', {required: true, maxLength: 20})}
                            />
                            {errors.first_name && errors.first_name.type === "required" && (
                                <span className='text-red-500' role="alert">First name is required</span>
                            )}
                            {errors.first_name && errors.first_name.type === "maxLength" && (
                                <span className='text-red-500' role="alert">Max length exceeded</span>
                            )}
                            <br />
                            <label htmlFor='last_name'>Last Name</label>
                            <input
                                type="text"
                                className="form-input mt-1 block w-full border"
                                placeholder="Last name"
                                name="lastName"
                                id='last_name'
                                aria-invalid={errors.last_name ? "true" : "false"}
                                {...register('last_name',{required: true, maxLength: 20})}
                            />
                            {errors.last_name && errors.last_name.type === "required" && (
                                <span className='text-red-500' role="alert">Last name is required</span>
                            )}
                            {errors.last_name && errors.last_name.type === "maxLength" && (
                                <span className='text-red-500' role="alert">Max length exceeded</span>
                            )}
                            <br />
                            <label htmlFor='email'>Email</label>
                            <input
                                type="text"
                                className="form-input mt-1 block w-full border"
                                placeholder="Email"
                                name="email"
                                id='email'
                                aria-invalid={errors.email ? "true" : "false"}
                                {...register('email',{required: true, pattern: /^\S+@\S+$/i, maxLength: 50})}
                            />
                            {errors.email && errors.email.type === "required" && (
                                <span className='text-red-500' role="alert">Email is required</span>
                            )}
                            {errors.email && errors.email.type === "maxLength" && (
                                <span className='text-red-500' role="alert">Max length exceeded</span>
                            )}
                            {errors.email && errors.email.type === "pattern" && (
                                <span className='text-red-500' role="alert">Please enter a valid email address</span>
                            )}
                            <br />
                            <label htmlFor='phone'>Phone Number</label>
                            <input
                                type="tel" className="form-input mt-1 block w-full border"
                                placeholder="Phone number"
                                name="phoneNumber"
                                id='phone'
                                aria-invalid={errors.phone ? "true" : "false"}
                                {...register('phone',{required: true, maxLength: 12})}
                            />
                            {errors.phone && errors.phone.type === "required" && (
                                <span className='text-red-500' role="alert">Phone number is required</span>
                            )}
                            {errors.phone && errors.phone.type === "maxLength" && (
                                <span className='text-red-500' role="alert">Max length exceeded</span>
                            )}
                            <br />
                            <label htmlFor='message'>Message</label>
                            <textarea
                                className="form-textarea mt-1 block w-full border"
                                name="message"
                                id='message'
                                rows={7}
                                aria-invalid={errors.message ? "true" : "false"}
                                {...register('message',{required: true, maxLength: 1000})}
                            />
                            {errors.message && errors.message.type === "required" && (
                                <span className='text-red-500' role="alert">Message is required</span>
                            )}
                            {errors.message && errors.message.type === "maxLength" && (
                                <span className='text-red-500' role="alert">Max length exceeded</span>
                            )}

                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-1"
                                type="submit"
                            >
                                Submit
                            </button>
                        </form>
                    </div>

                </div>
            </Layout>
        </div>
    );

}

export default ContactPage